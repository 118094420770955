import React from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from './Pages/components/Footer/Footer';
import Home from './Pages/Home';
import Header from './Pages/components/Header/Header';

import Arts from './Pages/Arts';
import Entertainment from './Pages/Entertainment';
import Sports from './Pages/Sports';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/arts' element={<Arts />} />
          <Route path='/entertainment' element={<Entertainment />} />
          <Route path='/sports' element={<Sports />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
