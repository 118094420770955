import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import OurPartners from '../components/OurPartners'
import '../resources/css/Entertainment.css'
import entertainmentData from '../localhostDB/EntertainmentDB.json';
import InnerBannerSportsImg from '../resources/images/Entertainment-Home-Cover.jpg';
import InnerBanner from './components/Banner/InnerBanner';
import LetsConnectForm from '../components/LetsConnectForm';
import SingleBar from '../components/SingleBar';
import entertainmentAbout from '../resources/images/entertainment_about_01.jpg';

const Entertainment: React.FC = (props) => {
    const classNames = ["oneLine", "twoLine", "threeLine", "fourLine"];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return (
        <>
            <div id="entertainmentBanner">
                <InnerBanner
                    bannerTitle="Creating Memorable"
                    bannerTitleSubLine="Event Experiences"
                    bannerText=" "
                    bannerLinkText="BUY TICKETS ONLINE"
                    bannerLink="https://www.google.com/"
                    bannerImg={InnerBannerSportsImg}
                    themeClass="entertainmentBannerTop"
                    bannerPageName="entertainment"
                />
            </div>
            <section className='aboutText aboutUsEntertainment' id="entertainmentAboutUs">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-4 my-3" data-aos="fade-up">
                            <div className="OurPartnersLogoImgInner">
                                <img src={entertainmentAbout} className='img-fluid' alt="About Cosmos" />
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-7 my-3" data-aos="fade-up">
                            <div className="sectionHeader whiteLine lineLeft">
                                <h2>About Cosmos Entertainment</h2>
                            </div>
                            <div className="aboutTextInner">
                                <p>Cosmos Entertainment was formed in 2019 and is headquartered in Riyadh, Saudi Arabia. We specialise in acquiring exclusive and unique international Intellectual Properties (IPs) for entertainment, event and experience-related activities within the Kingdom of Saudi Arabia.</p>
                                <p>Our portfolio of activations consists of leading world-acclaimed immersive experiences, art activations, edutainment-related exhibitions, Michelin culinary events, and international acts such as US Magician ‘Criss Angel’, and other family theatrical entertainment shows.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='sportSourServices' id="entertainmentServices">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader skyeLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Services</h2>
                                </div>
                                <p data-aos="fade-up" data-aos-delay="200">Cosmos Entertainment is experienced in partnering with International IPs for securing, delivering and executing the event in the Kingdom.</p>
                            </div>
                        </div>
                        <div className='clearfix'></div>
                    </div>
                    <div className="ServicesListEn">
                        <div className="row gx-5">
                            {entertainmentData.services.map((service, index) => (
                                <div className="col-lg-3 col-md-6 my-3" data-aos="fade-up" data-aos-delay={300 + index * 100} key={index}>
                                    {/* Select a class name based on the index */}
                                    <div className={`ServicesListBoxEn ${classNames[index % classNames.length]}`}>
                                        <h3>{service.title}</h3>
                                        <ul>
                                            {service.items.map((item, itemIndex) => (
                                                <li key={itemIndex}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className='OurPortfolio' id="entertainmentPortfolio">
                <div className="entertainmentPage">
                    <div className="container">
                        <div className="col-md-8 mx-auto">
                            <div className="text-center">
                                <div className="sectionHeader skyeLine" data-aos="fade-up" data-aos-delay="100">
                                    <h2>Our Portfolio</h2>
                                </div>
                                {/* <p data-aos="fade-up" data-aos-delay="200">Cosmos Sports has partnered with the best-in-class in their respective market sectors.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        {entertainmentData.EntertainmentText.map((item, index) => (
                            <div className="col-12 col-sm-6 col-lg-4 p-0" data-aos="zoom-in-up" data-aos-delay="300" key={index}>
                                <div className="portfolio-thumb effect-3 Entertainment">
                                    <div className="effect-img">
                                        <img src={item.portfolioThumb} alt={item.portfolioName} />
                                    </div>
                                    <div className="portfolioOverlayBox effect-text">
                                        <div className="portfolioTet">
                                            <h2>{item.portfolioName}</h2>
                                            <p>{item.portfolioText}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <div id="entertainmentClients">
                <OurPartners clientsLineCl="skyeLine" />
            </div>
            <SingleBar />
            <div id="entertainmentContactUs">
                <LetsConnectForm letsConnectText="If your IP, event or experience is unique, innovative and best-in-class, then please reach out to us using the contact form below, and one of our team members will get back to you shortly!" formLineCl="skyeLine" />
            </div>
        </>
    )
}

export default Entertainment
